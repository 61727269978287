import React, { useEffect } from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import BaseLayout from "../components/core/BaseLayout"
import HeaderImage from "../components/core/HeaderImage"
import OfferList from "../components/core/offerList/OfferList"
import OfferSearch from "../components/core/OfferSearch"
import "../styles/layout.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import clientData from "./data/data.json"
import GetRandomInt from "../components/functions/getRandomNumber"

function HomePage({ pageContext: { fechedOfferList } }) {
  const [initialOfferList, setInitialOfferList] = React.useState(
    fechedOfferList
  )
  const [offerList, setOfferList] = React.useState(fechedOfferList)
  const [imgUrl, setImgUrl] = React.useState("")

  const headerImageHomePage = () => {
    console.log(imgUrl, "1")

    if (imgUrl === "") {
      console.log(imgUrl, "2")

      let imageNumber = GetRandomInt(2)
      let imageUrl = `/${clientData.headerImage[imageNumber]}`
      setImgUrl(imageUrl)
      return imageUrl
    } else {
      console.log(imgUrl, "3")
      return imgUrl
    }
  }

  return (
    <>
      <BaseLayout>
        <SEO title="Home" />
        <HeaderImage headerImageSrc={headerImageHomePage()} />
        {console.log(offerList, "offerList")}
        <OfferSearch
          initialOfferList={initialOfferList}
          setOfferList={setOfferList}
        />
        <hr />

        <OfferList offerList={offerList} />
      </BaseLayout>
    </>
  )
}

export default HomePage

import React from "react"
import { Col, Card, Row } from "react-bootstrap"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import WorkIcon from "@material-ui/icons/Work"
import "./offerCard.scss"

const OfferCard = ({ offerData }) => (
  <div className="job-search-container">
    <a href={`/offres/${offerData.titrePage}-${offerData.numeroInterne}`}>
      <Card className="textAlignCenter job-search-card">
        <div className="corner-top"></div>
        <Card.Body>
          <Card.Title>{offerData.titreOffre}</Card.Title>
          <Card.Text>{offerData.nomEmployeur}</Card.Text>
          <Card.Text>
            <Row className="job-footer-infos">
              <Col sm="6">
                <WorkIcon style={{ paddingRight: 5 }} />
                <span>{offerData.categorie}</span>
              </Col>
              <Col sm="6">
                <span>
                  <LocationOnIcon />
                  {offerData.villeEmployeur}
                </span>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
        <div className="corner-bottom"></div>
      </Card>
    </a>
  </div>
)

OfferCard.propTypes = {}

OfferCard.defaultProps = {}

export default OfferCard

import React from "react"
import { FormControl, Button, InputGroup, Form } from "react-bootstrap"

function OfferSearch(props) {
  const [searchValue, setSearchValue] = React.useState(null)

  const handleSearch = event => {
    event.preventDefault()
    let offerFoundList = []
    if (searchValue === null || searchValue === undefined) {
      props.setOfferList(props.initialOfferList)
    } else {
      const initialOfferList = props.initialOfferList
      let _searchValue = searchValue
      _searchValue = _searchValue.toUpperCase()
      offerFoundList = initialOfferList.filter(
        offer =>
          RegExp(_searchValue).test(offer.titrePage.toUpperCase()) ||
          RegExp(_searchValue).test(offer.descriptionPoste.toUpperCase())
      )
      props.setOfferList(offerFoundList)
    }
  }

  const handleSearchOnChange = () => {
    let offerFoundList = []
    if (searchValue === null || searchValue === undefined) {
      props.setOfferList(props.initialOfferList)
    } else {
      const initialOfferList = props.initialOfferList
      let _searchValue = searchValue
      _searchValue = _searchValue.toUpperCase()
      offerFoundList = initialOfferList.filter(
        offer =>
          RegExp(_searchValue).test(offer.titrePage.toUpperCase()) ||
          RegExp(_searchValue).test(offer.descriptionPoste.toUpperCase())
      )
      props.setOfferList(offerFoundList)
    }
  }

  return (
    <div className="job-search-container">
      <Form
        onSubmit={handleSearch}
        style={{
          width: "100%",
          justifyContent: "center",
          alignItem: "center",
          display: "flex",
        }}
        inline
      >
        <InputGroup className="searchBar">
          <FormControl
            placeholder="Entrez votre recherche ici..."
            aria-label="jobSearch"
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value.trim())
              handleSearchOnChange()
            }}
          />
          <InputGroup.Append>
            <Button variant="outline-secondary" onClick={handleSearch}>
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </div>
  )
}

OfferSearch.propTypes = {}

OfferSearch.defaultProps = {}

export default OfferSearch
